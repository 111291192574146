body {
	background-color: #eeefe6;
	font-family: "Open Sans", sans-serif;
	color: #28292a;
	font-size: 1.0625rem;
	line-height: 1.5em;
	font-weight: 400;
}

.inside-content-background {
	color: #28292a;
	background: #ffffff;
}

h1 {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: -2px;
	color: #734120;
	font-size: 2.75em;
	line-height: 1.375em;
	font-weight: 400;
	text-transform: uppercase;
}

h2 {
	margin-top: 1.25em;
	margin-bottom: 0.5em;
	color: #734120;
	font-size: 1.75em;
	line-height: 1.375em;
	font-weight: 400;
}

h3 {
	margin-top: 1.25em;
	margin-bottom: 0.5em;
	color: #734120;
	font-size: 1.4375em;
	line-height: 1.375em;
	font-weight: 400;
}

h4 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	color: #734120;
	font-size: 1.25em;
	line-height: 1.375em;
	font-weight: 400;
}

h5 {
	margin-top: 1.5em;
	margin-bottom: 0.75em;
	color: #734120;
	font-size: 0.9375em;
	line-height: 1.4375em;
	font-weight: 400;
}

h6 {
	margin-top: 1.75em;
	margin-bottom: 0.875em;
	color: #734120;
	font-size: 0.875em;
	line-height: 1.625em;
	font-weight: 400;
}

p {
	position: relative;
	margin-top: 0em;
	margin-bottom: 0.75em;
}

a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #6a5435;
	font-weight: 400;
	text-decoration: none;
}

.main-content-wrap a,
.right-sidebar-column a {
	text-decoration: underline;
}

a:hover {
	color: #867053;
	text-decoration: none;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

li {
	margin-bottom: 0.25em;
}

label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 400;
}

strong {
	font-weight: 500;
}

em {
	font-style: italic;
}

blockquote {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1em 1.25em;
	border-left: 5px solid #613111;
	background-color: #eeefe6;
	color: #663515;
	font-size: 1.0625em;
	line-height: 1.5em;
	font-weight: 400;
}

.main-content-column blockquote p {
	margin: 0;
	padding: 0;
}

.right-sidebar-section-wrap.tools .contentbox_item button[type="submit"].primary,
.button {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 1em 1.5em;
	background-color: #225e7c;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #fff;
	font-size: 0.875em;
	line-height: 1.125em;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.right-sidebar-section-wrap.tools .contentbox_item button[type="submit"].primary:hover,
.button:hover {
	background-color: #67686b;
	color: #fff;
}

.button.secondary {
	background-color: #da031f;
	font-weight: 600;
	color: #fff;
}

.button.secondary:hover {
	background-color: #7c7072;
	color: #fff;
}

.button.right-sidebar-button {
	font-size: 1rem;
}

.button.slider-cta-button {
	margin-bottom: 0.5em;
	padding: 0.5em 1.75em 0.5em 0em;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-top: 1px none rgba(22, 29, 27, 0.15);
	background-color: transparent;
	background-image: url('/20170713122722/assets/images/arrow-icon-brown-01.svg');
	background-position: 98% 50%;
	background-size: 9px 9px;
	background-repeat: no-repeat;
	color: #da031f;
	font-size: 1em;
	line-height: 1.5em;
	font-weight: 700;
}

.button.slider-cta-button:hover {
	color: #b40219;
}

.button.tertiary {
	padding: 0.7em 0.75em;
	background-color: #663515;
	font-size: 0.75em;
	font-weight: 600;
}

.button.tertiary:hover {
	background-color: #734120;
}

.container {
	max-width: 1160px;
	padding-right: 25px;
	padding-left: 25px;
}

.container.header-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1180px;
	padding-right: 10px;
	padding-left: 10px;
}

.container.top-header-bar-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 42px;
	padding-top: 0px;
	padding-right: 0;
	padding-bottom: 4px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.page-title-container {
	position: relative;
	left: 0px;
	right: 0px;
	bottom: 0px;
	padding-right: 0px;
	padding-left: 0px;
}

.nav-section {
	position: fixed;
	top: 0px;
	width: 100%;
	background-color: transparent;
	background-image: none;
	background-position: 0px 0px;
}

.nav-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1px;
	padding: 0.75rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.1);
	color: #fff;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.nav-link:hover {
	background-color: #663515;
	color: #fff;
}

.nav-link.w--current {
	color: rgba(233, 236, 227, 0.5);
}

.nav-link.nav-search-button {
	position: relative;
	max-width: 4.5rem;
	min-height: 3.5rem;
	padding: 0rem;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	-webkit-box-flex: 3.25rem;
	-webkit-flex: 3.25rem;
	-ms-flex: 3.25rem;
	flex: 3.25rem;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	cursor: pointer;
}

.nav-link.nav-search-button:hover {
	opacity: 0.6;
}

.nav-menu-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.logo {
	z-index: 5000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 280px;
	margin-top: -43px;
	padding: 0.5rem 1.25rem 0.5rem 1rem;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 280px;
	-ms-flex: 0 0 280px;
	flex: 0 0 280px;
	background-color: #fff;
	color: #fff;
}

.logo:hover {
	color: #fff;
}

.footer-section {
	position: relative;
	background-color: #fff;
	background-image: url("/20170713122722/assets/images/textured-stripes-50pct.png"),
		-webkit-gradient(linear, left top, left bottom, from(#734120), to(#734120));
	background-image: url("/20170713122722/assets/images/textured-stripes-50pct.png"),
		linear-gradient(180deg, #734120, #734120);
	background-position: 0px 0px, 0px 0px;
	background-size: 128px, auto;
	color: #fff;
	font-size: 0.8125em;
	line-height: 1.75em;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.row.home-locations-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.row.content-box-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.row.footer-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.column.footer-signoff-text-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-left: 0rem;
}

.column.content-box-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.column.footer-left-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.column.footer-right-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 3rem 20px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(97, 49, 17, 0.85);
}

.column.hero-cbox-text-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.column.hero-cbox-image-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 1rem;
}

.logo-image {
	width: 90%;
	max-width: 240px;
	float: left;
}

.footer-signoff-section {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	background-color: #613111;
	background-image: url('/20170713122722/assets/images/textured-stripes-50pct.png');
	background-position: 0px 0px;
	background-size: 128px;
	color: #fff;
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 1.5rem;
	color: #fff;
}

.copyright-text h1 {
	display: inline-block;
	color: #fff;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 400;
	margin: 0;
	padding: 0;
	text-transform: none;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-top: 0.25em;
	margin-right: 1rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 32px;
	margin-right: 1em;
	padding-left: 32px;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	background-position: 50% 50%;
	background-size: 130px;
	background-repeat: no-repeat;
	opacity: 0.75;
	color: hsla(0, 0%, 100%, 0);
	font-size: 0.5rem;
	line-height: 9px;
	font-weight: 400;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	opacity: 1;
	color: rgba(195, 198, 200, 0);
}

.footer-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.footer-column-wrap.contact-details-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.header-content-right-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 2%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.header-contents-wrap {
	position: relative;
	z-index: 100;
	border-top: 1px solid hsla(0, 0%, 100%, 0.04);
	background-color: rgba(0, 0, 0, 0.65);
	background-image: url("/20170713122722/assets/images/textured-stripes-50pct.png"),
		-webkit-gradient(linear, left top, left bottom, from(#734120), to(#734120));
	background-image: url("/20170713122722/assets/images/textured-stripes-50pct.png"),
		linear-gradient(180deg, #734120, #734120);
	background-position: 0px 0px, 0px 0px;
	background-size: 128px, auto;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: -0.5rem 0.0625rem 1.5em;
	padding-top: 0.875em;
	padding-bottom: 0.875em;
	padding-left: 0px;
	color: rgba(122, 146, 139, 0.5);
	font-size: 1em;
	line-height: 1em;
	font-weight: 400;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.75em;
	margin-bottom: 0.25rem;
	float: left;
	font-size: 0.625em;
	line-height: 1.25em;
}

.header-menu-wrap {
	position: relative;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.right-sidebar-field {
	width: 100%;
	margin-top: 0.375em;
	margin-bottom: 1.25em;
	padding-right: 4px;
	padding-left: 8px;
}

.form-field.right-sidebar-field.miles-from {
	width: 52%;
}

.form-field.right-sidebar-field.zip-code {
	width: 45%;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-bottom: 0em;
	float: left;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.breadcrumb-divider {
	margin-left: 0.75em;
	float: right;
}

.left-nav-column-container {
	padding-right: 20px;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	box-shadow: none;
}

.left-nav-list-item {
	margin-bottom: 1px;
	box-shadow: 0 -1px 0 0 #e9ece3;
}

.left-nav-list-link {
	display: block;
	padding: 0.75em 1em;
	background-image: url("/20170713122722/assets/images/Arrow4_right_blk_25.svg");
	background-position: 5px 21px;
	background-size: 6px 8px;
	background-repeat: no-repeat;
	color: #663515;
	font-size: 1em;
	line-height: 1.3125em;
	font-weight: 600;
}

.left-nav-list-link:hover {
	color: #da031f;
}

.left-nav-list-link.w--current {
	background-color: rgba(233, 236, 227, 0.5);
	color: #da031f;
}

.left-nav-list-link.w--current:hover {
	color: #da031f;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em 0em 0em 1em;
	border-top: 1px solid #e9ece3;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
	box-shadow: 0 -1px 0 0 #e9ece3;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 2.25em 0.8125em 0em;
	color: #663515;
	font-size: 0.75em;
	line-height: 1.25em;
}

.left-nav-nested-list-link:hover {
	color: #da031f;
}

.left-nav-nested-list-link.w--current {
	padding-right: 1.25em;
	padding-left: 1em;
	background-color: rgba(233, 236, 227, 0.15);
	box-shadow: inset 2px 0 0 0 #da031f;
	color: #da031f;
	font-weight: 400;
}

.left-nav-nested-list-link.w--current:hover {
	color: #da031f;
}

.right-sidebar-section-wrap {
	position: relative;
	margin-left: 1em;
	padding-top: 0.75em;
	padding-right: 5px;
	padding-left: 5px;
	border-top: 1px solid #e9ece3;
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-sidebar-section-wrap.documents {
	margin-bottom: 0.75em;
}

.right-sidebar-section-title {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	color: #663515;
	font-size: 1.375em;
	font-weight: 600;
}

.right-sidebar-list {
	padding-left: 0px;
}

.right-sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	padding-right: 15px;
	padding-left: 15px;
	background-color: #eeefe6;
	background-image: url('/20170713122722/assets/images/burlap-texture-2-01.jpg');
	background-position: 0px 0px;
}

.secondary-nav-icon {
	width: 1rem;
	height: 1.25rem;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.secondary-nav-text {
	float: left;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: #b4bfcc;
	font-size: 0.75rem;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	color: #fff;
}

.slider-section {
	position: relative;
	display: block;
	max-width: 1160px;
	margin-right: auto;
	margin-left: auto;
	background-color: #364652;
}

.slider {
	position: relative;
	display: block;
	height: 75vh;
	max-height: 650px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-mask {
	position: relative;
}

.slider-arrow {
	left: auto;
	top: auto;
	right: 3rem;
	bottom: 2rem;
	width: 3rem;
	height: 3rem;
	background-color: #494e4c;
	opacity: 0.7;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 1.0625rem;
}

.slider-arrow:hover {
	background-color: #734120;
	opacity: 1;
	color: #fff;
}

.slider-arrow.left-arrow {
	margin-right: 3.5rem;
}

.slider-arrow.left-arrow:hover {
	background-color: #734120;
	color: #fff;
}

.slider-indicator {
	left: 2rem;
	bottom: 2rem;
	height: 3rem;
	margin-right: 12rem;
	margin-left: 680px;
	padding-top: 0.25rem;
	padding-bottom: 0px;
	font-size: 0.5rem;
	line-height: 3rem;
	text-align: right;
}

.slider-slide {
	position: relative;
}

.home-content-box-section {
	position: relative;
	display: block;
	max-width: 1160px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2.5em;
	padding-bottom: 2.5em;
	border-top: 0px none #e9ece3;
	border-bottom: 4px none #fff;
	background-color: rgba(186, 162, 130, 0.25);
}

.home-content-box-section.last-cbox-section {
	background-color: #fff;
	background-image: none;
}

.home-image-link {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 15vw;
	max-height: 160px;
	min-height: 120px;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-top: 4px solid #baa282;
	background-color: #f3f5f7;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	color: #204f21;
}

.home-image-link.large-image-link {
	height: 25vw;
	max-height: 300px;
	min-height: 200px;
}

.home-content-box-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	background-color: #fff;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.home-content-box-wrap:hover {
	box-shadow: 0 3px 10px 1px rgba(107, 117, 113, 0.25);
}

.home-content-box-wrap.last-row-cbox:hover {
	box-shadow: none;
}

.home-content-box-wrap.hero-section-box-wrap {
	margin-left: 15px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	background-color: transparent;
	font-weight: 400;
}

.home-content-box-wrap.hero-section-box-wrap:hover {
	box-shadow: none;
}

.home-content-box-title {
	margin-top: 0.375em;
	margin-bottom: 0.375em;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 100%;
	-ms-flex: 1 0 100%;
	flex: 1 0 100%;
	color: #aa9869;
	font-size: 1.3125em;
	line-height: 1.25em;
}

.home-content-box-title.hero-content-box-title {
	font-size: 1.75em;
}

.home-content-box-learn-more {
	display: inline-block;
	margin-top: 0.25rem;
	padding-top: 0.625em;
	padding-right: 1.75em;
	padding-bottom: 0.625em;
	clear: both;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	background-image: url("/20170713122722/assets/images/arrow-icon-brown-01.svg");
	background-position: 98% 50%;
	background-size: 9px 9px;
	background-repeat: no-repeat;
	color: #da031f;
	font-size: 1em;
	line-height: 1.5em;
	font-weight: 700;
	text-transform: uppercase;
}

.home-content-box-learn-more:hover {
	color: #b40219;
}

.slider-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-caption-wrap {
	position: absolute;
	left: 0px;
	top: auto;
	right: auto;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 580px;
	margin-bottom: 2rem;
	margin-left: 1.75rem;
	padding: 1.25rem 1.75rem 0.25rem 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-top: 4px none #fff;
	background-color: hsla(0, 0%, 100%, 0.85);
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(hsla(0, 0%, 100%, 0.35)),
		to(hsla(0, 0%, 100%, 0))
	);
	background-image: linear-gradient(
		90deg,
		hsla(0, 0%, 100%, 0.35),
		hsla(0, 0%, 100%, 0)
	);
	color: #28292a;
	font-size: 1.0625em;
	line-height: 1.5em;
	font-weight: 400;
	text-align: left;
}

.inside-page-header-content-wrap {
	position: relative;
	margin-bottom: 0em;
	padding: 10em 0em 0em;
	border-bottom: 1px none #c3c6c8;
	text-decoration: none;
}

.inside-page-header-content-wrap h1 {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: 0px;
	padding: 1rem 25px 1.25rem;
	background-color: rgba(186, 162, 130, 0.25);
	color: #fff;
	font-size: 2.75em;
	line-height: 1.375em;
	font-weight: 600;
	text-transform: uppercase;
}

@media (max-width: 991px) {
	.inside-page-header-content-wrap h1 {
		margin-left: -1px;
		font-size: 2.25em;
	}
}

@media (max-width: 767px) {
	.inside-page-header-content-wrap h1 {
		font-size: 2em;
	}
}

@media (max-width: 479px) {
	.inside-page-header-content-wrap h1 {
		font-size: 1.75em;
	}
}

.inside-page-header-section {
	position: relative;
	margin-right: -15px;
	margin-left: -15px;
	padding-top: 42px;
	padding-right: 15px;
	padding-left: 15px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: transparent;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(40, 41, 42, 0.35)),
		to(rgba(40, 41, 42, 0.35))
	),
		url("/20170713122722/assets/images/banner.jpg");
	background-image: linear-gradient(
		180deg,
		rgba(40, 41, 42, 0.35),
		rgba(40, 41, 42, 0.35)
	),
		url("/20170713122722/assets/images/banner.jpg");
	background-position: 0px 0px, 50% 75%;
	background-size: auto, cover;
}

.inside-content-background {
	position: relative;
	display: block;
	max-width: 1160px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2.5rem;
	padding-bottom: 4rem;
	background-color: #fff;
}

.inside-content-background.inside-header-background {
	padding-top: 0rem;
	padding-bottom: 0rem;
	border-right: 1px none hsla(0, 0%, 100%, 0.5);
	background-color: transparent;
}

.main-content-wrap.nested-content-wrap {
	margin-right: 0.5em;
}

.header-menu-inside-wrap {
	position: relative;
	display: block;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
}

.intro-text-style {
	color: #28292a;
	font-size: 1.1875em;
	line-height: 1.5em;
	font-weight: 400;
}

.home-content-box-text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.75em 1.5em 0.5em 1em;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: space-between;
	-ms-flex-line-pack: justify;
	align-content: space-between;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-flex: 1;
	-webkit-flex: auto;
	-ms-flex: auto;
	flex: auto;
}

.slider-caption-title {
	margin-top: 0em;
	margin-bottom: 0.1875em;
	padding-bottom: 0.0625em;
	color: #734120;
	font-size: 2em;
	line-height: 1.25em;
	font-weight: 600;
}

.footer-links-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-bottom: 0em;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.footer-links-list-item {
	max-width: 47%;
	margin-bottom: 2px;
	-webkit-box-flex: 0;
	-webkit-flex-grow: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-webkit-flex-basis: 47%;
	-ms-flex-preferred-size: 47%;
	flex-basis: 47%;
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.1);
}

.footer-links-list-item:nth-child(odd) {
	margin-right: 3%;
}

.left-nav-list-content-wrap {
	display: block;
	height: auto;
	margin-top: -2.5rem;
	padding-top: 2rem;
	padding-bottom: 3em;
	border-right: 1px solid #e9ece3;
}

.link.pagination-link {
	margin: 0.5em 0.25em;
	float: left;
	font-weight: 500;
	text-transform: uppercase;
}

.link.pagination-link.next {
	float: right;
}

.link.content-box-title-link {
	color: #734120;
	font-weight: 600;
	text-decoration: none;
}

.link.content-box-title-link:hover {
	color: #867053;
}

.link.content-box-title-link.cbox-title-link-on-dark {
	border-bottom-color: rgba(195, 198, 200, 0.35);
	color: #734120;
	font-weight: 600;
	text-decoration: none;
}

.link.content-box-title-link.cbox-title-link-on-dark:hover {
	color: #778996;
}

.link.breadcrumb-list-link {
	color: #613111;
	font-weight: 600;
	text-decoration: underline;
}

.link.breadcrumb-list-link:hover {
	color: #867053;
	text-decoration: none;
}

.link.breadcrumb-list-link.breadcrumb-current {
	color: #663515;
	text-decoration: none;
}

.link.footer-text-link {
	color: #fff;
	text-decoration: underline;
}

.link.footer-text-link:hover {
	color: #fff;
	text-decoration: none;
}

.link.footer-text-link.footer-quick-link-list-link {
	display: block;
	padding: 0.625em 0.5em 0.625em 1.25em;
	color: #fff;
	font-size: 0.9375em;
	line-height: 1.375em;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.link.footer-text-link.footer-quick-link-list-link:hover {
	background-image: url("/20170713122722/assets/images/arrow-double-white.svg");
	background-position: 0% 50%;
	background-size: 9px 9px;
	background-repeat: no-repeat;
	color: #fff;
}

.pagination-middle-wrap {
	display: inline-block;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.pagination-middle-text {
	display: inline-block;
	margin-right: 0.5em;
	margin-left: 0.5em;
}

.pagination-middle-select {
	display: inline-block;
	width: auto;
	max-height: 2.25rem;
	margin-bottom: 0px;
	padding: 0px 0px 0px 2px;
}

.pagination-wrap {
	margin-bottom: 1.5em;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	border-top: 1px solid #e9ece3;
	border-bottom: 1px solid #e9ece3;
	font-size: 0.875em;
	line-height: 1.25em;
	text-align: center;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #e9ece3;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.loc-view-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-view-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 48%;
	padding-top: 0.5em;
	padding-right: 0.25em;
	padding-bottom: 0.5em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: solid;
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	opacity: 0.55;
	-webkit-transition: color 150ms ease, background-color 150ms ease,
		opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease,
		all 200ms ease;
	transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease,
		border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	color: #000;
	font-weight: 700;
}

.loc-view-button-wrap:hover {
	border-color: rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.loc-view-button-wrap.w--current {
	border-color: #000;
	opacity: 0.7;
}

.loc-view-button-wrap.w--current:hover {
	opacity: 1;
}

.loc-miles-from-zip-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-list-section-title {
	margin-top: 0em;
}

.loc-select-type-form-wrap {
	position: relative;
}

.loc-type-form {
	position: relative;
}

.loc-type-form-checkbox {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-bottom: 0.75em;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	line-height: 1.375em;
}

.loc-type-form-checkbox.select-all-wrap {
	font-weight: 700;
}

.loc-type-form-checkbox.sidebar-checkbox {
	width: 100%;
	margin-bottom: 0.5em;
}

.loc-type-form-checkbox.sidebar-checkbox.select-all {
	margin-top: 0.75em;
	font-weight: 600;
}

.loc-type-form-checkbox-box {
	position: relative;
	width: 1.5em;
	height: 1.5em;
	margin-top: 0px;
	margin-right: 0.5em;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.loc-type-form-checkbox-box.select-all-checkbox {
	width: 1.4125em;
	height: 1.4125em;
	margin-right: 0.5em;
}

.loc-type-form-checkbox-label {
	position: relative;
	float: none;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.loc-type-form-checkbox-label.select-all-label {
	font-weight: 700;
	text-transform: uppercase;
}

.loc-type-form-checkboxes-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1.25em;
	padding-top: 1em;
	padding-bottom: 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	border-bottom: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-type-form-checkbox-image {
	position: relative;
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.5em;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-sidebar-search-reveal-button {
	position: relative;
	display: none;
	margin-bottom: 0em;
	padding: 0.75em 0.5em 0.75em 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	font-size: 1.125em;
	line-height: 1.25em;
	font-weight: 600;
	cursor: pointer;
}

.loc-sidebar-search-reveal-button:active {
	color: #777;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.right-sidebar-refine-search-button-text {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: 0.25em;
	font-size: 1.125em;
	line-height: 1.25em;
}

.loc-refine-search-checkbox-wrap {
	padding-top: 0.5em;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-view-button-text {
	margin-left: 0.25em;
}

.loc-view-button-image {
	position: relative;
	display: block;
	width: 1.125rem;
	height: 1.375rem;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 20%;
}

.nav-drop-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px solid hsla(0, 0%, 100%, 0.1);
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 78.8%, 0.5);
}

.nav-drop-icon {
	margin-right: 1.5rem;
	color: #fff;
	font-size: 0.875em;
}

.nav-drop-list {
	overflow: hidden;
	display: block;
	height: 0;
	top: 100%;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: auto;
	-ms-flex: auto;
	flex: auto;
	background-color: #baa282;
}

.nav-drop-list-link {
	margin-top: 1px;
	padding: 0.5em 1.5rem 0.5em 0.25rem;
	box-shadow: 0 -1px 0 0 rgba(115, 65, 32, 0.05);
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5em;
}

.nav-drop-list-link:hover {
	padding-right: 1.25rem;
	padding-left: 0.5rem;
	color: #734120;
}

.nav-drop-list-link.w--current {
	font-size: 0.875rem;
	color: #3ba2d3;
}

.nav-drop-toggle-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.75rem 1.5rem 0.75rem 0.75rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.nav-drop-toggle:hover .nav-drop-icon {
	color: #fff;
}

.nav-drop-toggle-link:hover {
	background-color: #663515;
	color: hsla(80, 19%, 91%, 0.5);
}

.nav-drop-toggle-link.w--current {
	background-color: #663515;
	color: hsla(80, 19%, 91%, 0.5);
	text-transform: uppercase;
}

.header-container {
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.loader-wrap {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 5000;
	display: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #212931;
}

.loader-image {
	width: 100px;
	height: 100px;
}

.nav-link-search-icon {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 1em;
	height: 1em;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.footer-column-subtitle {
	margin-top: 0.375em;
	margin-bottom: 0.25em;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 100%;
	-ms-flex: 1 0 100%;
	flex: 1 0 100%;
	color: #fcfcfc;
	font-size: 1.25em;
	line-height: 1.5em;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-contact-block {
	padding-right: 4%;
	padding-left: 1rem;
	-webkit-box-flex: 1;
	-webkit-flex: auto;
	-ms-flex: auto;
	flex: auto;
	border-left: 1px solid hsla(0, 0%, 100%, 0.1);
}

.footer-contact-block.narrow-footer-block {
	max-width: 30%;
	padding-right: 2%;
	padding-left: 0.75rem;
	border-left-style: none;
}

.footer-contact-block-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 65%;
	-ms-flex-preferred-size: 65%;
	flex-basis: 65%;
}

.nav-drop-list-content {
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
	padding: 0.5em 0.625em 0.625em;
	border-top: 3px solid #b8d6ba;
}

.home-hero-section {
	position: relative;
	display: block;
	max-width: 1160px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1em;
	padding-bottom: 3em;
	background-color: #fff;
}

.home-hero-cbox-link {
	position: relative;
	display: block;
	margin-left: 15px;
}

.nav-link-search-close {
	position: absolute;
	left: 50%;
	top: 50%;
	display: none;
	width: 0.9375em;
	height: 0.9375em;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.slider-caption-text-wrap {
	margin-right: 3rem;
}

.header-language-section {
	position: relative;
	height: 42px;
	background-image: url("/20170713122722/assets/images/textured-stripes-50pct.png"), -webkit-gradient(linear, left top, left bottom, from(#613111), to(#613111));
	background-image: url("/20170713122722/assets/images/textured-stripes-50pct.png"), linear-gradient(180deg, #613111, #613111);
	background-position: 0px 0px, 0px 0px;
	background-size: 128px, auto;
	color: #fff;
	font-weight: 400;
}

.header-secondary-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1.5em;
	padding-left: 0em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	font-size: 0.8125rem;
	line-height: 1.25em;
	text-transform: uppercase;
}

.header-secondary-item.header-link-item {
	display: none;
	margin-bottom: 3px;
	padding-right: 0.5em;
	padding-left: 0em;
	border-right-style: none;
	border-left-style: none;
}

.header-secondary-item.first-secondary-item {
	padding-left: 0em;
	border-left-style: none;
}

.home-content-box-learn-more-section {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 100%;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
}

.home-content-box-text-wrap-inside {
	-webkit-box-flex: 1;
	-webkit-flex: auto;
	-ms-flex: auto;
	flex: auto;
}

.header-secondary-item-text {
	color: #fff;
	font-weight: 600;
}

.header-secondary-item-text.header-tagline-text {
	color: #fff;
	font-size: 0.75rem;
	font-weight: 600;
}

.home-image-link-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(40, 41, 42, 0.5)),
		to(rgba(40, 41, 42, 0.5))
	);
	background-image: linear-gradient(
		180deg,
		rgba(40, 41, 42, 0.5),
		rgba(40, 41, 42, 0.5)
	);
	opacity: 1;
}

.home-image-link-overlay.slider-image-overlay {
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(92, 95, 10, 0.15)),
		to(rgba(92, 95, 10, 0.15))
	),
		-webkit-gradient(linear, left top, left bottom, from(rgba(92, 95, 10, 0.15)), to(rgba(92, 95, 10, 0.15)));
	background-image: linear-gradient(
		180deg,
		rgba(92, 95, 10, 0.15),
		rgba(92, 95, 10, 0.15)
	),
		linear-gradient(180deg, rgba(92, 95, 10, 0.15), rgba(92, 95, 10, 0.15));
	opacity: 0.3;
}

.footer-phone {
	position: relative;
	display: inline-block;
	width: 60%;
	float: right;
}

.header-secondary-item-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1em;
	padding-left: 0.75em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	background-color: #2a779b;
	color: #f3f6f7;
	font-weight: 400;
}

.header-secondary-item-link:hover {
	background-color: #161a1d;
	color: #fff;
}

.footer-hours {
	width: 50%;
	float: right;
}

.footer-right-column-wrap {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.slider-caption-paragraph {
	margin-bottom: 0.25em;
	color: #28292a;
}

.header-secondary-item-icon-image {
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.375em;
	opacity: 1;
}

.header-secondary-item-icon-image.header-phone {
	width: 1.375em;
	height: 1.375em;
	margin-right: 0.625em;
	opacity: 1;
}

.content-box-section-title-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1.5em;
	padding: 0.625em 1em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url("/20170713122722/assets/images/textured-stripes-50pct.png"),
		-webkit-gradient(linear, left top, left bottom, from(#734120), to(#734120));
	background-image: url("/20170713122722/assets/images/textured-stripes-50pct.png"),
		linear-gradient(180deg, #734120, #734120);
	background-position: 0px 0px, 0px 0px;
	background-size: 128px, auto;
}

.home-content-box-section-title {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-bottom: 0.0625em;
	color: #fff;
	font-size: 1.625em;
	line-height: 1.375em;
	font-weight: 600;
}

.footer-logo-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	opacity: 0.7;
}

.footer-logo-link:hover {
	opacity: 1;
}

.footer-logo-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-flex-basis: 130px;
	-ms-flex-preferred-size: 130px;
	flex-basis: 130px;
}

.footer-disclaimer {
	margin-top: 0em;
	font-size: 0.6875rem;
	line-height: 1.4125em;
}

.footer-copyright-wrap {
	margin-right: 0px;
	margin-left: 20px;
	font-weight: 400;
}

.content-box-section-title-wrap-corner {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 20px;
	height: 20px;
	border-right: 25px solid #dcddd3;
	border-bottom: 20px solid transparent;
}

.body-background {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 1280px;
	background-color: #f2f3e8;
}

.body-background-fade {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 800px;
	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		from(#eeefe6),
		to(rgba(238, 239, 230, 0))
	);
	background-image: linear-gradient(0deg, #eeefe6, rgba(238, 239, 230, 0));
}

.body-background-image {
	position: relative;
	height: 100%;
	background-image: url("/20170713122722/assets/images/burlap-texture-2-01.jpg");
	background-position: 50% 0px;
	background-repeat: repeat;
}

.home-content-wrap {
	padding-right: 10px;
	padding-left: 10px;
}

.footer-social-icon-link {
	width: 1.75rem;
	height: 1.75rem;
	min-width: 0px;
	margin-top: 0.5rem;
	margin-right: 0.75rem;
	margin-bottom: 0.25rem;
	float: none;
	background-color: rgba(115, 65, 32, 0.85);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2a779b;
}

.footer-social-icon-link:hover {
	background-color: #734120;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.footer-social-icon-image {
	float: left;
}

.footer-social-icon-image.youtube {
	padding: 1px;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000002;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}
/* media queries */
@media (max-width: 991px) {
	h1 {
		margin-left: -1px;
		font-size: 2.25em;
	}

	h2 {
		font-size: 1.6875em;
	}

	h3 {
		font-size: 1.375em;
	}

	.container {
		padding-right: 20px;
		padding-left: 20px;
	}

	.container.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.container.top-header-bar-container {
		height: 35px;
		min-height: 35px;
		padding-top: 0px;
		padding-right: 6px;
		padding-bottom: 0px;
	}

	.nav-link {
		z-index: 140;
		display: block;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1em 1rem;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		font-size: 1.125rem;
		line-height: 1.375rem;
		font-weight: 400;
		text-align: left;
		text-transform: none;
	}

	.nav-link:hover {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #734120;
	}

	.nav-link.w--current {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #734120;
		color: #734120;
	}

	.nav-link.w--current:hover {
		color: #663515;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
	}

	.nav-link.mobile-list-back {
		padding-left: 0.75rem;
		background-color: transparent;
		color: #734120;
		cursor: pointer;
	}

	.nav-menu-wrap {
		display: block;
		padding-top: 0px;
		padding-bottom: 50vh;
		background-color: #baa282;
		text-align: left;
	}

	.logo {
		max-width: 260px;
		margin-top: -47px;
		padding-top: 0.75rem;
		padding-bottom: 0.65rem;
		-webkit-flex-basis: 260px;
		-ms-flex-preferred-size: 260px;
		flex-basis: 260px;
		font-size: 1.375rem;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: center;
	}

	.row.home-locations-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.content-box-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.footer-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-text-column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-left: 10px;
		text-align: center;
		margin-bottom: 1rem;
	}

	.column.content-box-column {
		-webkit-box-flex: 0;
		-webkit-flex-grow: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.column.footer-left-column {
		padding-top: 2rem;
		padding-bottom: 0rem;
	}

	.column.footer-right-column {
		padding: 0rem 0.75em 2.5rem;
		background-color: transparent;
	}

	.column.hero-cbox-text-column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.footer-signoff-section {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.footer-signoff-list {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.footer-signoff-grip {
		margin-top: 2rem;
		margin-right: 0em;
		float: none;
	}

	.footer-column-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: center;
	}

	.footer-column-wrap.contact-details-wrap {
		margin-bottom: 1em;
		padding-bottom: 1.5em;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
	}

	.header-content-right-wrap {
		display: block;
		float: right;
		-webkit-box-flex: 0;
		-webkit-flex-grow: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}

	.header-contents-wrap {
		border-top-color: hsla(0, 0%, 100%, 0.03);
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.breadcrumb-list {
		margin-top: 0rem;
		border-bottom: 1px solid #e9ece3;
	}

	.header-menu-wrap {
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.form-field.right-sidebar-field {
		margin-bottom: 1.5em;
	}

	.form-field.right-sidebar-field.miles-from {
		width: 100%;
		margin-bottom: 0.25em;
	}

	.form-field.right-sidebar-field.zip-code {
		width: 100%;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0em;
		float: none;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		border-right: 1px solid hsla(0, 0%, 100%, 0.05);
		border-left-style: none;
	}

	.right-sidebar-column {
		margin-top: 1rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.right-sidebar-section-wrap {
		margin-left: 0em;
		padding-right: 0px;
		padding-left: 0px;
	}

	.section-wrap.inside-content-section {
		padding-right: 0px;
		padding-left: 0px;
	}

	.secondary-nav-icon {
		position: absolute;
		left: 1rem;
		top: 1.125rem;
		bottom: 1.125rem;
		width: 1.25rem;
		height: 1.25rem;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding: 0.75rem 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #fff;
		font-size: 0.9375rem;
		line-height: 1.25em;
		font-weight: 600;
	}

	.secondary-nav-list-link:hover {
		background-color: #663515;
		color: #fff;
	}

	.secondary-nav-list-link.w--current {
		color: #fff;
	}

	.slider {
		height: 60vh;
		max-height: none;
		min-height: 525px;
	}

	.slider-arrow {
		right: 0.75rem;
		bottom: 1.25rem;
		width: 2.5rem;
		height: 2.5rem;
		font-size: 1rem;
	}

	.slider-arrow.left-arrow {
		margin-right: 3rem;
	}

	.slider-indicator {
		left: 0.75rem;
		bottom: 1.25rem;
		overflow: hidden;
		height: 2.5rem;
		margin-right: 7rem;
		margin-left: 60%;
		padding-top: 0rem;
	}

	.home-image-link {
		height: 23vw;
		max-height: 230px;
		float: left;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.home-image-link.large-image-link {
		height: 23vw;
		max-height: 230px;
		min-height: 120px;
	}

	.home-content-box-wrap {
		width: 100%;
		margin-top: 1em;
		margin-bottom: 1em;
	}

	.home-content-box-wrap.hero-section-box-wrap {
		display: block;
		width: auto;
		margin: 0em auto 0;
		float: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.home-content-box-title {
		line-height: 1.1875em;
	}

	.slider-caption-wrap {
		max-width: 60%;
		margin-bottom: 1.25rem;
		margin-left: 0px;
		padding-left: 20px;
		font-size: 0.9375em;
	}

	.inside-page-header-content-wrap {
		padding-right: 0em;
		padding-bottom: 1.25em;
		padding-left: 0em;
	}

	.inside-page-header-section {
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-content-background {
		padding-top: 0.125rem;
		padding-bottom: 2rem;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0em;
	}

	.header-menu-inside-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		max-width: 100%;
	}

	.home-content-box-text-wrap {
		-webkit-flex-shrink: 1;
		-ms-flex-negative: 1;
		flex-shrink: 1;
	}

	.footer-links-list-item {
		max-width: 23%;
		margin-bottom: 1px;
		-webkit-flex-basis: 23%;
		-ms-flex-preferred-size: 23%;
		flex-basis: 23%;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.link.footer-text-link.footer-quick-link-list-link {
		padding-top: 1.25em;
		padding-bottom: 1.25em;
	}

	.left-nav-reveal-button {
		display: block;
		margin-bottom: 1.25em;
		padding: 1em 50px;
		background-color: #5b636b;
		background-image: url("/20170713122722/assets/images/menu_hamburger_stagger_wht.svg");
		background-position: 17px 50%;
		background-size: 19px;
		background-repeat: no-repeat;
		color: #dae2ec;
	}

	.left-nav-reveal-button:hover {
		color: #fff;
	}

	.left-nav-reveal-button:active {
		background-color: #50575e;
	}

	.loc-miles-from-zip-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 33.33%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 33.33%;
		margin-bottom: 0.75em;
	}

	.loc-type-form-checkbox.sidebar-checkbox.select-all {
		width: 100%;
		margin-top: 0.75em;
	}

	.loc-type-form-checkboxes-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.loc-sidebar-search-reveal-button {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.loc-refine-search-checkbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0.75em;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.1);
		background-image: url("/20170713122722/assets/images/icon_arrow2_right_wht_50.svg");
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25),
			inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 1.125rem;
		line-height: 1.375rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-top: 0px;
		padding-bottom: 100vh;
		background-color: #baa282;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url("/20170713122722/assets/images/icon_arrow2_left_wht_50.svg");
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.footer-contact-block {
		text-align: left;
	}

	.footer-contact-block.narrow-footer-block {
		max-width: 26%;
		padding-left: 1px;
		border-left-style: none;
	}

	.footer-contact-block-wrap {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.home-hero-cbox-link {
		margin-left: 0px;
		padding: 0px;
	}

	.slider-caption-text-wrap {
		margin-right: 2rem;
	}

	.header-language-section {
		height: 35px;
	}

	.header-secondary-item.header-link-item {
		margin-bottom: 0px;
		padding: 0px 0px 0px 6px;
		border-right-style: none;
		border-left-style: none;
		font-size: 0.75rem;
	}

	.home-content-box-learn-more-section {
		margin-top: 0.25em;
		padding-top: 0.25em;
	}

	.header-secondary-item-text {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
	}

	.secondary-nav-icon-search-close {
		position: absolute;
		left: 1.5rem;
		top: 1.1875rem;
		bottom: 1.1875rem;
		display: none;
		width: 1.125rem;
		height: 1.125rem;
		margin-right: 0.5rem;
		opacity: 0.4;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.footer-phone {
		color: #fff;
		font-weight: 300;
	}

	.header-secondary-item-link {
		padding-right: 1.5em;
		padding-left: 1em;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		background-color: transparent;
		font-size: 0.8125rem;
	}

	.header-secondary-item-link:hover {
		background-color: #663515;
		color: #663515;
	}

	.footer-right-column-wrap {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.header-secondary-item-icon-image {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		opacity: 1;
	}

	.header-secondary-item-icon-image.header-phone {
		width: 1.375em;
		height: 1.375em;
	}

	.content-box-section-title-wrap {
		margin-top: 1em;
		font-size: 1rem;
	}

	.footer-logo-wrap {
		-webkit-flex-basis: 130px;
		-ms-flex-preferred-size: 130px;
		flex-basis: 130px;
	}

	.body-background {
		display: none;
	}

	.home-content-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.column.hero-cbox-text-column {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}
}

@media (max-width: 767px) {
	body {
		font-size: 1rem;
		line-height: 1.5em;
	}

	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.625rem;
		line-height: 1.5em;
	}

	h3 {
		font-size: 1.25rem;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}

	.header-contents-wrap {
		height: 82px;
	}

	.button.slider-cta-button {
		font-size: 1em;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.top-header-bar-container {
		padding: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.logo {
		max-width: 200px;
		margin-top: 0px;
		padding: 0.75rem 1rem 1rem 0.8125rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-flex-basis: 200px;
		-ms-flex-preferred-size: 200px;
		flex-basis: 200px;
	}

	.footer-section {
		font-size: 0.875em;
		line-height: 1.5em;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: center;
	}

	.row.home-locations-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-text-column {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: center;
	}

	.column.footer-left-column {
		padding-bottom: 0rem;
	}

	.column.footer-right-column {
		padding-right: 10px;
		padding-left: 10px;
	}

	.footer-signoff-section {
		padding-top: 1.5rem;
	}

	.copyright-text {
		margin: 1em 1rem;
	}

	.footer-signoff-list-item {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.footer-signoff-grip {
		margin-top: 1rem;
		float: none;
	}

	.footer-column-wrap.contact-details-wrap {
		padding-bottom: 0em;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-style: none;
	}

	.header-content-right-wrap {
		margin-left: 0%;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-webkit-flex-shrink: 1;
		-ms-flex-negative: 1;
		flex-shrink: 1;
	}

	.breadcrumb-list-item {
		font-size: 0.6875em;
	}

	.header-menu-wrap {
		height: 100%;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.secondary-nav-list {
		max-width: 60%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.right-sidebar-section-wrap {
		margin-left: 0rem;
	}

	.secondary-nav-icon {
		left: 1rem;
	}

	.secondary-nav-list-link {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.slider-section {
		background-color: #fff;
		background-image: none;
		color: #333333;
	}

	.slider {
		height: auto;
	}

	.slider-indicator {
		left: 20px;
		top: 445px;
		bottom: auto;
		overflow: hidden;
		margin-top: -3.25rem;
		margin-left: 0rem;
		text-align: left;
	}

	.home-image-link {
		height: 45vw;
		max-height: 300px;
		min-height: 200px;
		margin-right: 0%;
		border-right-style: none;
	}

	.home-image-link.large-image-link {
		height: 45vw;
		max-height: 300px;
		min-height: 200px;
	}

	.home-content-box-wrap {
		float: none;
	}

	.home-content-box-wrap.hero-section-box-wrap {
		margin-bottom: 0em;
		padding-right: 0.5em;
		padding-left: 0.5em;
		font-size: 0.9375rem;
		line-height: 1.5em;
		text-align: left;
	}

	.home-content-box-learn-more {
		font-size: 0.9375em;
	}

	.slider-image {
		position: relative;
		height: 445px;
	}

	.slider-image.slide-3 {
		position: relative;
		bottom: auto;
	}

	.slider-caption-wrap {
		position: relative;
		bottom: auto;
		width: 100%;
		max-width: 100%;
		margin-right: 0%;
		margin-bottom: 0vh;
		margin-left: 0%;
		padding: 25px 22px 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: transparent;
		background-image: none;
		font-size: 1em;
	}

	.inside-page-header-content-wrap {
		margin-right: 10px;
		margin-left: 10px;
		padding-top: 12em;
	}

	.inside-page-header-section {
		min-height: 80px;
	}

	.header-menu-inside-wrap {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.intro-text-style {
		font-size: 1.125em;
	}

	.home-content-box-text-wrap {
		font-size: 1rem;
		line-height: 1.5em;
	}

	.slider-caption-title {
		margin-top: 0em;
		font-size: 1.75em;
	}

	.footer-links-list-item {
		max-width: 48%;
		-webkit-flex-basis: 48%;
		-ms-flex-preferred-size: 48%;
		flex-basis: 48%;
	}

	.link.footer-text-link.footer-quick-link-list-link {
		padding: 1em 0.875em 1em 1.25rem;
	}

	.pagination-middle-wrap {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.loc-type-form-checkbox {
		width: 50%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 50%;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.footer-contact-block {
		min-width: 0%;
		margin-bottom: 1em;
		padding-right: 0%;
		padding-bottom: 1em;
		padding-left: 0em;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
		border-left-style: none;
	}

	.footer-contact-block.narrow-footer-block {
		max-width: none;
		padding-right: 0%;
		padding-left: 0px;
	}

	.footer-contact-block-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.home-hero-cbox-link {
		padding: 0px;
		border-top: 4px solid #baa282;
	}

	.slider-caption-text-wrap {
		margin-right: 0.75rem;
	}

	.header-secondary-item {
		padding-right: 12px;
		padding-left: 12px;
	}

	.header-secondary-item.header-link-item {
		padding: 0px;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		border-right-style: none;
	}

	.header-secondary-item.first-secondary-item {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.header-secondary-item-text {
		margin-right: 8px;
	}

	.home-image-link-overlay.slider-image-overlay {
		height: 445px;
	}

	.secondary-nav-icon-search-close {
		left: 1rem;
	}

	.footer-phone {
		width: 48%;
	}

	.header-secondary-item-link {
		padding-right: 0em;
		padding-left: 0em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.footer-hours {
		width: 48%;
	}

	.content-box-section-title-wrap {
		margin-right: 10px;
		margin-left: 10px;
	}

	.footer-copyright-wrap {
		margin-top: 0;
		margin-left: 0px;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.content-box-section-title-wrap-corner {
		width: 15px;
		height: 15px;
		border-right-width: 20px;
		border-bottom-width: 15px;
	}

	.footer-social-icon-link {
		margin-right: 1rem;
		margin-left: 0rem;
	}

	.slider-arrow {
		bottom: auto;
		top: 445px;
		margin-top: -3.25rem;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 1.75rem;
	}

	h2 {
		font-size: 1.4375rem;
	}

	h3 {
		font-size: 1.125rem;
	}

	.container.header-container {
		padding-left: 0px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.container.top-header-bar-container {
		padding-left: 0px;
	}

	.logo {
		max-width: 150px;
		padding: 0.875rem 0.75rem 1rem;
		-webkit-flex-shrink: 1;
		-ms-flex-negative: 1;
		flex-shrink: 1;
		-webkit-flex-basis: 150px;
		-ms-flex-preferred-size: 150px;
		flex-basis: 150px;
	}

	.copyright-text {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.header-content-right-wrap {
		margin-right: 0em;
		margin-left: 0%;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.secondary-nav-list {
		max-width: 100%;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.secondary-nav-icon {
		width: 1.125rem;
		height: 1.125rem;
		margin-right: 0rem;
	}

	.secondary-nav-list-link {
		max-width: 3rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.home-image-link {
		min-height: 180px;
		float: none;
	}

	.home-image-link.large-image-link {
		min-height: 180px;
	}

	.home-content-box-title {
		line-height: 1.125em;
	}

	.header-menu-inside-wrap {
		margin-left: 5px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.home-content-box-text-wrap {
		float: none;
		font-size: 0.9375rem;
		line-height: 1.5em;
	}

	.slider-caption-title {
		font-size: 1.625em;
	}

	.pagination-middle-wrap {
		min-width: 100%;
		margin-top: 0.75em;
		padding-top: 0.75em;
		border-top: 1px solid rgba(84, 159, 196, 0.25);
	}

	.loc-type-form-checkbox {
		width: 100%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 100%;
	}

	.footer-contact-block {
		padding-right: 0%;
	}

	.footer-contact-block.narrow-footer-block {
		padding-right: 0%;
	}

	.footer-contact-block-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-right-style: none;
	}

	.header-secondary-item.header-link-item {
		border-right-style: none;
	}

	.header-secondary-item-text {
		margin-right: 0px;
	}

	.footer-phone {
		position: relative;
		left: auto;
		width: 55%;
	}

	.header-secondary-item-link {
		font-size: 0.75rem;
	}

	.footer-hours {
		width: 55%;
	}

	.home-content-box-section-title {
		font-size: 1.5em;
	}
}

/***************** Webflow Styles *******************/
/****************************************************/
html {
	font-size: 1rem;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

.footer-section a[href^="tel"] {
	-webkit-transition: all 150ms ease;
	color: #fff;
}

input[type="text"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/****************** Tablesaw ***********************/
/****************************************************/
table.styled.tablesaw th,
table.styled.tablesaw td {
	border-left: 0;
	border-top: 0;
}

@media (max-width: 50em) {
	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0;
	}
}

@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printHeader,
#printFooter {
	display: none;
}

/****************** Header ***********************/
/*************************************************/
.header-menu-wrap {
	height: 100%;
}

.header-secondary-item-text.phone-number,
.header-secondary-item-text.phone-number a {
	color: #f7fcfe !important;
	text-transform: uppercase;
}

@media (max-width: 991px) {
	.container.header-container {
		height: 100%;
	}

	.header-contents-wrap {
		height: 80px;
		max-height: 80px;
		transition: all 300ms ease;
	}

	.secondary-nav-text {
		margin-top: -1px;
	}

	.secondary-nav-list-link {
		height: 79px;
		transition: all 300ms ease;
	}
	/* sticky */
	.sticky .header-contents-wrap {
		height: 65px;
		max-height: 65px;
	}

	.sticky .logo {
		max-width: 230px;
		padding-bottom: 0.5rem;
		padding-top: 0.4rem;
	}

	.sticky .secondary-nav-list-link {
		height: 64px;
	}

	.menu-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex !important;
		height: 79px;
		padding: 0px 1.5rem 0px 1rem;
		float: right;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
		color: #fff;
		line-height: 79px;
		font-weight: 400;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		background-color: #663515;
		color: #fff;
	}

	.menu-button.w--open {
		background-color: transparent;
		color: #fff;
	}

	.sticky .menu-button {
		display: flex !important;
		height: 64px;
		align-items: center;
	}

	.sticky .menu-button-icon {
		line-height: 1;
	}

	.menu-button-icon {
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		font-size: 2rem;
		line-height: 65px;
	}

	.menu-button-icon:hover {
		color: #fff;
	}

	.menu-button-text {
		margin-left: 0.125rem;
		float: right;
		color: #fff;
		font-size: 0.93rem;
		line-height: 64px;
		font-weight: 600;
		display: flex;
		align-items: center;
		letter-spacing: 0.05em;
	}
}

@media (max-width: 767px) {
	.header-contents-wrap {
		border: 0;
	}

	.sticky .logo {
		max-width: 180px;
	}

	.menu-button {
		height: 79px;
		padding-right: 1.125rem;
		padding-left: 0.75rem;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.menu-button-icon {
		line-height: 65px;
	}

	.menu-button-text {
		font-size: 0.93rem;
		line-height: 65px;
	}

	.sticky .menu-button {
		height: 65px;
	}
}

@media (max-width: 479px) {
	.header-contents-wrap {
		height: 69px;
	}

	.sticky .logo {
		max-width: 130px;
	}

	.menu-button {
		height: 68px;
		padding-right: 1rem;
		padding-left: 0.75rem;
		border-left-style: none;
	}

	.menu-button.w--open {
		color: hsla(0, 0%, 100%, 0.4);
		padding-right: 1rem;
	}

	.menu-button-icon {
		line-height: 4.25rem;
	}

	.menu-button-text {
		line-height: 4.25rem;
	}

	.sticky .header-contents-wrap {
		height: 60px;
		max-height: 60px;
	}

	.sticky .menu-button {
		height: 60px;
	}
}

/****************** Accesible menu ****************/
/*************************************************/
ul.nav-menu-wrap {
	margin: 0px;
	padding: 0px;
}

ul.nav-menu-wrap li {
	margin-bottom: 0px;
	list-style-type: none;
}

.nav-menu-wrap .nav-drop-toggle .nav-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1px;
	padding: 0.75rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.1);
	color: #fff;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	height: 61px;
}

.nav-drop-toggle.hasChildren .nav-link {
	padding-right: 35px;
}

.nav-drop-toggle.hasChildren .nav-link:after {
	content: "\e603";
	position: absolute;
	right: 12px;
	font-family: "webflow-icons";
}

.nav-menu-wrap .nav-drop-toggle.hasChildren.open .nav-link:after {
	color: #fff;
}

.nav-menu-wrap .nav-drop-toggle.hasChildren .container-wrapper {
	position: absolute;
	left: 0;
	top: 100%;
	bottom: auto;
	overflow: hidden;
	margin: 0px;
	height: 0;
	min-width: 100%;
}

.nav-menu-wrap .nav-drop-toggle:last-of-type.hasChildren .container-wrapper {
	left: auto;
	right: 0;
}

.nav-menu-wrap .nav-drop-toggle.hasChildren.open .container-wrapper {
	opacity: 1;
	height: auto;
}

.nav-menu-wrap .nav-drop-toggle.hasChildren .container-wrapper .nav-drop-list {
	height: auto !important;
	position: static;
	padding: 0.5em 0.625em 0.625em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
	background: #663515;
	margin: 0px;
	width: 100%;
}

.nav-menu-wrap .nav-drop-toggle.hasChildren .container-wrapper .nav-drop-list li a {
	margin-top: 1px;
	padding: 0.5em 1.5rem 0.5em 0.25rem;
	box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07);
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5em;
	display: block;
}

.nav-menu-wrap .nav-drop-toggle.hasChildren.open .container-wrapper .nav-drop-list li a:hover,
.nav-menu-wrap .nav-drop-toggle.hasChildren .container-wrapper .nav-drop-list li a.w--current {
	padding-right: 1.25rem;
	padding-left: 0.5rem;
	color: #c4b095;
}

.nav-menu-wrap .nav-drop-toggle.open .nav-link,
.nav-menu-wrap .nav-drop-toggle.active-li .nav-link,
.nav-menu-wrap .nav-drop-toggle .nav-link:hover {
	background-color: #663515;
	color: #fff;
}

/****************** Mobile navigation ****************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
		max-width: none;
	}
}


.mobile-navigation-menu {
	background: #663515;
}

.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
	-webkit-transform: translate(-80%, 0);
	-ms-transform: translate(-80%, 0);
	transform: translate(-80%, 0);
	-webkit-transform: translate3d(-80%, 0, 0);
	transform: translate3d(-80%, 0, 0);
}

.mobile-navigation-menu li .nav-link {
	margin-bottom: 1px;
	padding: 1em 1rem;
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	font-size: 1.125rem;
	line-height: 1.375rem;
	color: #fff;
	text-transform: capitalize;
}

.mobile-navigation-menu li .nav-link:hover {
	background-color: transparent;
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	color: #c4b095;
}

.mobile-navigation-menu li.active .nav-link {
	background-color: transparent;
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #c4b095;
	color: #c4b095;
}

.mobile-navigation-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mobile-navigation-menu .mm-listview > li.mm-selected > span {
	background: none;
}

.mobile-navigation-menu .mm-listview > li .mm-arrow:after,
.mobile-navigation-menu .mm-listview > li .mm-btn_next:after {
	border-color: #fff;
	background-color: rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease;
}

.mobile-navigation-menu .mm-listview .mm-btn_next {
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
}

.mobile-navigation-menu .mm-listview .mm-btn_next:before {
	border-color: #8c6850;
}

.mobile-navigation-menu .mm-listview .mm-btn_next:after {
	right: 22px;
}

.mobile-navigation-menu .mm-listview .mm-btn_next:hover:after {
	border-color: #c4b095;
}

.mobile-navigation-menu .mm-navbar {
	border-color: #8c6850;
	height: 55px;
	text-transform: capitalize;
}

.mobile-navigation-menu .mm-navbar a,
.mobile-navigation-menu .mm-navbar > * {
	color: #fff;
	text-align: left;
	margin: 7px 0px;
}

.mobile-navigation-menu .mm-navbar a:before {
	transition: all 0.3s ease;
}

.mobile-navigation-menu .mm-navbar:hover a,
.mobile-navigation-menu .mm-navbar:hover a:before {
	color: #c4b095;
	border-color: #c4b095;
}

.mobile-navigation-menu .mm-btn:after,
.mobile-navigation-menu .mm-btn:before {
	border-color: #fff;
}

.mobile-navigation-menu .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -5px;
}

.mm-listitem:after {
	left: 0;
}

/***************** Homepage  **************************/
/******************************************************/
.home-hero-section a.home-hero-cbox-link.home-section-link {
	text-align: center;
}

.cb2-more {
	width: 100%;
}

.homeboxes .content-box-row.w-row:before,
.homeboxes.content-box-row.w-row:after {
	content: "";
	display: inline;
}

@media (max-width: 991px) {
	.home-hero-section {
		padding-top: 2rem;
	}

	.column.hero-cbox-image-column {
		justify-content: center;
	}

	.home-hero-section a.home-hero-cbox-link.home-section-link .home-locations-image {
		max-width: 500px;
	}
}

@media (max-width: 767px) {
	.home-hero-section {
		padding-top: 0.5rem;
	}
}

@media (max-width: 560px) {
	.home-hero-section a.home-hero-cbox-link.home-section-link .home-locations-image {
		max-width: 100%;
	}
}

/***************** Slideshow  **************************/
/******************************************************/
.slider-caption-wrap {
	padding-bottom: 0.8rem;
}

.slider-caption-paragraph {
	line-height: 28px;
	margin-top: -1px;
}

.button.slider-cta-button {
	margin-bottom: 0;
}

.w-slider-dot {
	margin-left: 5px;
	margin-right: 5px;
}

.slider {
	min-height: 575px;
}

@media (max-width: 991px) {
	.slider-caption-wrap {
		padding-bottom: 12px;
	}

	.slider-caption-paragraph {
		line-height: inherit;
		margin-top: inherit;
	}

	.slider {
		min-height: 0;
		height: 445px;
	}
}

@media (max-width: 767px) {
	.w-slide {
		height: auto;
	}

	.slider-indicator {
		left: 20px;
	}

	.slider-caption-text-wrap {
		margin-top: 7px;
		width: 100%;
	}

	.slider-caption-wrap {
		padding-bottom: 10px;
	}

	.slider {
		height: auto;
		max-height: none;
	}

	.home-image-link-overlay.slider-image-overlay {
		height: 445px;
	}

	.slider-image {
		height: 445px;
	}
}

@media (max-width: 479px) {
	.slider-caption-wrap {
		padding-bottom: 18px;
	}

	.slider-caption-text-wrap {
		margin-top: 0;
	}

	.slider-image {
		height: 330px;
	}

	.home-image-link-overlay.slider-image-overlay {
		height: 330px;
	}

	.slider-arrow {
		height: 2rem;
		width: 2rem;
		top: 330px;
		margin-top: -2.7rem;
	}

	.slider-indicator {
		top: 313px;
		display: none;
	}

	.slider-arrow.left-arrow {
		margin-right: 2.5rem;
	}
}

/****************** Inside Page ******************/
/*************************************************/
.inside-row {
	min-height: 500px;
}

.inside-content-section.right-sidebar .main-content-column {
	padding-left: 0px;
	padding-right: 0px;
}

.inside-content-section.right-sidebar .main-content-column .main-content-column-nested {
	padding-left: 10px;
	padding-right: 10px;
}

.inside-content-section.right-sidebar .main-content-column .right-sidebar-column {
	padding-left: 10px;
	padding-right: 10px;
}

.left-nav-list-link.active.left-nav-link {
	background-color: hsla(80, 19%, 91%, 0.5);
	color: #da031f;
	font-weight: 600;
}

.left-nav-list-link.active.left-nav-link:hover {
	color: #da031f;
}

.left-nav-nested-list-link.active.left-nav-link {
	padding-right: 1.25em;
	padding-left: 1em;
	background-color: hsla(80, 19%, 91%, 0.15);
	box-shadow: inset 2px 0 0 0 #da031f;
	color: #da031f;
	font-weight: 600;
}

.left-nav-nested-list-link.active.left-nav-link:hover {
	color: #da031f;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

td img {
	max-width: none;
}

@media (max-width: 991px) {
	.body-background-image {
		background-image: none;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-column .box {
	margin-bottom: 2.5em;
	margin-left: 1em;
	padding-top: 0.75em;
	padding-right: 5px;
	padding-left: 5px;
	border-top: 1px solid #e9ece3;
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-sidebar-column .box h4,
.right-sidebar-column .box h3 {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	color: #734120;
	font-size: 1.375em;
	line-height: 1.375em;
	font-weight: 400;
}

.right-sidebar-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box.tools td:first-child img {
	margin-right: 5px;
}

@media (max-width: 991px) {
	.right-sidebar-column .box {
		margin-left: 0em;
		padding-right: 0px;
		padding-left: 0px;
	}
}

@media (max-width: 767px) {
	.right-sidebar-column .box h4,
	.right-sidebar-column .box h3 {
		line-height: 1.5em;
	}
}

/****************** Footer ***********************/
/*************************************************/
#GRIPFooterLogoText {
	line-height: 6px;
	text-align: right !important;
}

#GRIPFooterLogo {
	text-align: right;
	float: right;
	opacity: 0.75;
	margin-right: 8px;
	margin-top: -3px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

#GRIPFooterLogo:hover {
	opacity: 1;
	color: hsla(204, 4%, 77%, 0);
}

.footer-phone a[href^="tel:"] {
	font-weight: inherit;
	color: inherit;
}

.contact-us-wrapper {
	margin-bottom: 2rem;
}

.footer-phone.desktop {
	display: inline-block;
}

.footer-phone.mobile {
	display: none;
}

.link.footer-text-link.active {
	background-image: url('/20170713122722/assets/images/arrow-double-white.svg');
	background-position: 0px 50%;
	background-size: 9px 9px;
	background-repeat: no-repeat;
	color: #fff;
}

.footer-signoff-list-item a.footer-link {
	color: #b8d6ba;
}

@media (max-width: 991px) {
	.footer-phone.desktop {
		display: none;
	}

	.footer-phone.mobile {
		display: inline-block;
	}

	.column.grip-logo {
		align-items: center;
		display: flex;
		justify-content: center;
	}

	.footer-phone {
		color: #fff;
		font-weight: 300;
	}

	#GRIPFooterLogoText {
		text-align: center !important;
		float: none;
	}

	#GRIPFooterLogo {
		text-align: center;
	}
}

@media (max-width: 767px) {
	.footer-social-icon-link {
		margin-right: 1rem;
		margin-left: 0rem;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #6a5435;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #867053;
	text-decoration: none;
}

.main-content-column-nested ul li p {
	margin-bottom: 0;
}

.main-content-column p {
	margin-top: 0em;
	margin-bottom: 0.75em;
}

/* buttons */
.inside-row .button,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	display: inline-block;
	background-color: transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #da031f;
	font-size: 1em;
	line-height: 1.5em;
	font-weight: 700;
	text-align: center;
	border: 1px solid transparent;
	text-decoration: none !important;
	text-transform: uppercase;
	border-radius: 0px;
	background-image: url('/20170713122722/assets/images/arrow-icon-brown-01.svg');
	background-position: 98% 50%;
	background-size: 9px 9px;
	background-repeat: no-repeat;
	margin-right: 20px;
	margin-bottom: 5px;
	padding-top: 0.625em;
	padding-right: 1.75em;
	padding-bottom: 0.625em;
}

.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover {
	background-color: transparent;
	color: #b40219;
}

.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary {
	padding: 1em 1.5em !important;
	background-color: #da031f;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #fff;
	font-size: 0.929rem;
	line-height: 1.125em;
	font-weight: 400;
	text-align: center;
	border-bottom: 1px solid transparent;
	text-decoration: none !important;
	text-transform: uppercase;
	border-radius: 0px;
	margin-right: 20px;
	margin-bottom: 5px;
}

.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover {
	background-color: #b40219;
	color: #fff;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 1em 1.5em !important;
	background-color: #663515;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #fff;
	font-size: 0.929rem;
	line-height: 1.125em;
	font-weight: 400;
	text-align: center;
	border-bottom: 1px solid transparent;
	text-decoration: none !important;
	text-transform: uppercase;
	border-radius: 0px;
	margin-bottom: 5px;
}

.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #734120;
	color: #fff;
}

/* messages */
#message.success,
#polls .session_message.success {
	background-color: rgba(17, 185, 30, 0.25);
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #28292a;
	font-weight: 500;
}

#message.error,
#polls .session_message.error {
	background-color: rgba(253, 54, 54, 0.15);
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #28292a;
	font-weight: 500;
}

#message.success *,
#message.error *,
#polls .session_message.success p,
#polls .session_message.error p {
	color: #28292a;
	font-size: 1.0625rem;
	line-height: 1.5em;
	font-weight: 500;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(255, 255, 255, 0.75);
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #d6bd91;
	color: #663515;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #dfcba9;
	color: #663515;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
}

table.styled th a:hover {
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #f7f5f0;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea {
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #878787;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

.inside-row select {
	padding: 6px 12px;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
hr:not(.fc-divider):not(.formBreak) {
	border-color: #e9ece3;
	border: 0px;
	color: #e9ece3;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
}
